<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Merchandising from "@/services/Merchandising";
import Http from "@/services/Http";
import Swal from "sweetalert2";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Products",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Products",
      items: [
        {
          text: "Products",
          href: "/",
        },
      ],
      isCollapsed: true,
      filterData: false,
      isBusy: false,
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "Art_Sifra",
      sortDesc: true,
      fields: [
        {
          key: "Art_Sifra",
          label: "Product ID",
        },
        {
          key: "Art_Ime",
          label: "Product Name",
        },
        {
          key: "Art_Proizvoditel",
          label: "Brand",
        },
        {
          key: "Art_CenaP1",
          label: "Price",
        },
        "action",
      ],
      filterInput: {
        product_id: '',
        product_name: '',
        category: '0',
        tag: '',
        withpic: '',
      },
    };
  },
  middleware: "authentication",
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    iconClass() {
      return this.isCollapsed ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down';
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  async created() {
    // this.getProducts();
    this.getCategories();
    this.getTags();
    this.getFilters();
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },

    async getProducts() {
      try {
        this.filterData = true;
        this.toggleBusy();
        let filters = this.getFilters()
        const response = await Merchandising.getProducts(filters)
        console.log(response.data);
        this.tableData = response.data.data.products;
        console.log(this.tableData)
        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = [];
      }
    },
    async getCategories() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getCategories()
        console.log(response.data.data);
        this.categories = response.data.data
        console.log("kategorii", this.categories)

        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    async getTags() {
      try {
        this.toggleBusy();
        const response = await Merchandising.getTags()
        console.log(response.data.data);
        this.tags = response.data.data
        console.log("tags", this.tags)

        this.toggleBusy();
      } catch (error) {
        console.log("HERE");
        this.error = error.response.data.error ? error.response.data.error : "";
      }
    },
    getFilters() {
      var filter_string = '&limit=1000';
      filter_string += (this.filterInput.product_id) ? '&id=' + this.filterInput.product_id : "";
      filter_string += (this.filterInput.product_name) ? '&name=' + this.filterInput.product_name : "";
      filter_string += (this.filterInput.category) ? '&category=' + this.filterInput.category : "";
      filter_string += (this.filterInput.tag) ? '&tag=' + this.filterInput.tag : "";
      filter_string += (this.filterInput.withpic) ? '&withpic=' + this.filterInput.withpic : "";
      return filter_string = filter_string.substring(1);
    },
    refreshTable() { },
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `Match added!`,
        showConfirmButton: false,
        timer: 2000,
      });
      this.getProducts();
      this.getCategories();
    },
    async success() {
      this.getProducts();
    },
    failedmsg(msg) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: msg,
        showConfirmButton: false,
        timer: 50000,
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4 d-flex justify-content-center align-items-center">

              <a class="text-body fw-semibold pb-2 d-block" @click="toggleCollapse" data-toggle="collapse"
                href="javascript: void(0);" role="button" aria-expanded="false" v-b-toggle.collapse>

                <i :class="iconClass" class="accor-down-icon text-primary me-1"></i>Filters
              </a>
              <div class="col-md-8">
                <b-collapse visible id="collapse" class="mt-2">
                  <div class="p-4">
                    <b-form>

                      <b-form-group id="input-group-1" label="Product ID" label-for="input-1" class="mb-3">
                        <b-form-input id="input-1" type="text" v-model="filterInput.product_id"></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-1" label="Product Name" label-for="input-2" class="mb-3">
                        <b-form-input id="input-1" type="text" v-model="filterInput.product_name"></b-form-input>
                      </b-form-group>

                      <b-form-group id="input-group-3" label="Category" label-for="input-3" class="mb-3">
                        <select v-model="filterInput.category" class="form-control form-select" type="select">
                          <b-form-select-option value='0' disabled>Select a Category</b-form-select-option>
                          <b-form-select-option v-for="category in categories" :key="category.name"
                            :value="category.name">
                            {{ category.name }}
                          </b-form-select-option>
                        </select>
                        <b-icon icon="caret-down-fill" class="ml-auto mt-2"></b-icon>
                      </b-form-group>

                      <b-form-group id="input-group-4" label="Has Picture" label-for="input-4" class="mb-3">
                        <b-form-radio-group v-slot="{ ariaDescribedby }" v-model="filterInput.withpic">
                          <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios"
                            value="Y">Yes</b-form-radio>
                          <b-form-radio :aria-describedby="ariaDescribedby" name="some-radios" value="N">No</b-form-radio>
                        </b-form-radio-group>
                      </b-form-group>
                      <b-form-group id="input-group-5" label="Tag" label-for="input-5" class="mb-3">
                        <b-form-radio-group v-slot="{ ariaDescribedby }" v-model="filterInput.tag">
                          <b-form-radio v-for="tag in tags" :key="tag.id" :value="tag.id"
                            :aria-describedby="ariaDescribedby" name="some-radios" class="text-capitalize">{{ tag.tag_name
                            }}</b-form-radio>

                        </b-form-radio-group>
                      </b-form-group>

                      <div class="row mt-3">
                        <div class="col d-inline-flex">
                          <button type="button" class="btn btn-success me-2 w-lg" @click="getProducts">Filter</button>
                          <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                        </div>
                      </div>
                    </b-form>
                  </div>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="filterData">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4" >
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6" v-show="filterData">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ms-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <b-table :busy="isBusy" table-class="table table-centered datatable table-card-list"
              thead-tr-class="bg-transparent" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage"
              :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
              :filter-included-fields="filterOn" show-empty empty-text="No Data Found" @filtered="onFiltered" >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(Art_CenaP1)="data">
                {{ data.item.Art_CenaP1.split(".")[0] + ' ден' }}
              </template>
              <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <router-link title="Product Details" :to="{ path: `/merchandising/products/${data.item.Art_ID}`}">
                        <i class="uil uil-eye font-size-18"></i>
                      </router-link>
                    </li>
                  </ul>
                </template>
              
            </b-table>

            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.sm {
  width: 1rem;
  height: 1rem;
}
</style>
